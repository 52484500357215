import {
  DeviceSpecificationPayloadPost,
  DeviceSpecificationResponse,
  DeviceSpecificationResponseSearch,
  DeviceSpecificationPayloadPut,
} from "domain/entities/deviceSpecification";

import { ConfigurationType } from "globalTypes";

import axiosClient from "./axios";
import {
  createApiQuery,
  createSearchApiQuery,
  useApiMutation,
} from "./queryUtils";

export const deviceSpecificationApi = {
  getDeviceSpecification: (specificationId: string) =>
    axiosClient.get<DeviceSpecificationResponse>(
      `/hive/device/specification/${specificationId}`,
    ),
  createDeviceSpecification: (data: DeviceSpecificationPayloadPost) =>
    axiosClient.post<DeviceSpecificationResponse>(
      "/hive/device/specification",
      data,
    ),
  updateDeviceSpecificationById: (
    specificationId: string,
    data: DeviceSpecificationPayloadPut,
  ) =>
    axiosClient.put<DeviceSpecificationResponse>(
      `/hive/device/specification/${specificationId}`,
      data,
    ),
  deleteDeviceSpecificationById: (specificationId: string) =>
    axiosClient.delete<DeviceSpecificationResponse>(
      `/hive/device/specification/${specificationId}`,
    ),
};

export interface DeviceSpecificationSearchQueryParams {
  projectId: string | undefined;
  name?: string;
  type?: ConfigurationType;
}

export const useDeviceSpecificationSearch = createSearchApiQuery<
  DeviceSpecificationResponseSearch,
  undefined,
  DeviceSpecificationSearchQueryParams
>("/hive/device/specification");

export const useDeviceSpecificationById = createApiQuery<
  DeviceSpecificationResponse,
  { specificationId: string }
>("/hive/device/specification/{specificationId}", 60 * 1000);

export const useDeviceSpecificationMutationUpdate = (
  id: string | undefined,
) => {
  return useApiMutation(
    (payload: DeviceSpecificationPayloadPut) => {
      return deviceSpecificationApi.updateDeviceSpecificationById(
        id ?? "",
        payload,
      );
    },
    (response) => {
      return response.data?.responseDetail ?? "OK";
    },
    {
      meta: {
        dataKeys: [
          useDeviceSpecificationById.getPrimaryKey(),
          { pathParams: { specificationId: id } },
        ],
      },
    },
  );
};
