import {
  UserPayloadBody,
  UserPayloadPost,
  UserResponse,
  UserResponseSearch,
  ValidateUserFieldResponse,
} from "domain/entities/user";

import axiosClient from "./axios";
import { createApiQuery, createSearchApiQuery } from "./queryUtils";

export const userApi = {
  createUser: (data: UserPayloadPost) =>
    axiosClient.post<UserResponse>("/hive/user", data),
  updateUserById: (userId: string, data: UserPayloadBody) =>
    axiosClient.put<UserResponse>(`/hive/user/${userId}`, data),
  deleteUserById: (userId: string) =>
    axiosClient.delete<UserResponse>(`/hive/user/${userId}`),
  getUserById: (userId: string) =>
    axiosClient.get<UserResponse>(`/hive/user/${userId}`),
  validateUserUsername: (username: string) =>
    axiosClient.post<ValidateUserFieldResponse>(
      `/hive/user/validate/username`,
      { value: username },
    ),
  validateUserEmail: (email: string) =>
    axiosClient.post<ValidateUserFieldResponse>(`/hive/user/validate/email`, {
      value: email,
    }),
};

export interface UserSearchQueryParams {
  firstName?: string;
  lastName?: string;
  email?: string;
  enabled?: boolean;
  username?: string;
  expired?: boolean;
  locked?: boolean;
  credentialsExpired?: boolean;
}

export const useUserSearch = createSearchApiQuery<
  UserResponseSearch,
  undefined,
  UserSearchQueryParams
>("/hive/user");

export const useUserById = createApiQuery<UserResponse, { userId: string }>(
  "/hive/user/{userId}",
);
