import { ControllerRenderProps } from "react-hook-form";

import { AggregationFunction } from "globalTypes";

export enum TimeframeType {
  HISTORIC = "HISTORICAL",
  REALTIME = "REALTIME",
}

export enum RealTimeType {
  PREDEFINED = "PREDEFINED",
  CUSTOM = "CUSTOM",
}

export interface TimeframeBase {
  interval?: number;
  limit?: number;
  aggregation: AggregationFunction;
  dataPoints: number;
  type: string;
}

export interface TimeframeHistorical extends TimeframeBase {
  from: string;
  to: string;
}

export interface TimeframeRealtime extends TimeframeBase {
  realTimeType: RealTimeType;
  period: number;
}

export enum TimeIntervalInputType {
  LAST = "Last",
  CURRENT = "Current",
  FROM_TO = "From/To",
}

export interface TimeFrameDialogProps {
  anchor: HTMLElement | null;
  handleClose: () => void;
}

export interface TimeFrameFormProps {
  type: TimeframeType;
}

export interface CustomControlledInputProps {
  label?: string;
  field: ControllerRenderProps<any, any>;
}
