import {
  IntegrationPayloadPost,
  IntegrationResponse,
  IntegrationResponseSearch,
  IntegrationReferenceResponseSearch,
} from "domain/entities/integration";
import { IntegrationLogResponse } from "domain/entities/integrationLog";

import { ReferenceQueryParams } from "globalTypes";

import axiosClient from "./axios";
import {
  createApiQuery,
  createApiQueryInfinite,
  createSearchApiQuery,
  SearchParams,
  useApiMutation,
} from "./queryUtils";

export const integrationApi = {
  createIntegration: (data: IntegrationPayloadPost) =>
    axiosClient.post<IntegrationResponse>("/hive/integration", data),
  updateIntegrationById: (
    integrationId: string,
    data: IntegrationPayloadPost,
  ) =>
    axiosClient.put<IntegrationResponse>(
      `/hive/integration/${integrationId}`,
      data,
    ),
  deleteIntegrationById: (integrationId: string) =>
    axiosClient.delete<IntegrationResponse>(
      `/hive/integration/${integrationId}`,
    ),
  startIntegrationById: (integrationId: string) =>
    axiosClient.put<IntegrationResponse>(
      `/hive/integration/start/${integrationId}`,
    ),
  stopIntegrationById: (integrationId: string) =>
    axiosClient.put<IntegrationResponse>(
      `/hive/integration/stop/${integrationId}`,
    ),
  testIntegration: (integrationId: string) =>
    axiosClient.get<IntegrationLogResponse>(
      `/hive/integration/test/${integrationId}`,
    ),
};

export interface IntegrationSearchQueryParams {
  projectId: string;
  name?: string;
  key?: string;
}

export interface IntegrationReferenceQueryParams extends ReferenceQueryParams {}

export const useIntegrationSearch = createSearchApiQuery<
  IntegrationResponseSearch,
  undefined,
  IntegrationSearchQueryParams
>("/hive/integration");

export const useIntegrationSearchInfinite = createApiQueryInfinite<
  IntegrationResponseSearch,
  undefined,
  IntegrationSearchQueryParams & SearchParams,
  number
>("/hive/integration", {
  getNextPageParam: (lastPage) => {
    if (lastPage.page < lastPage.numberOfPages) {
      return lastPage.page + 1;
    }
  },
});

export const useIntegrationById = createApiQuery<
  IntegrationResponse,
  { integrationId: string }
>("/hive/integration/{integrationId}", 60 * 1000);

export const useIntegrationMutationUpdate = (id: string | undefined) =>
  useApiMutation(
    (data: IntegrationPayloadPost) => {
      return integrationApi.updateIntegrationById(id ?? "", data);
    },
    (response) => {
      return response.data?.responseDetail ?? "OK";
    },
    {
      meta: {
        dataKeys: [
          useIntegrationById.getPrimaryKey(),
          { pathParams: { integrationId: id } },
        ],
      },
    },
  );

export const useIntegrationReferenceSearch = createApiQuery<
  IntegrationReferenceResponseSearch,
  undefined,
  IntegrationReferenceQueryParams
>("/hive/integration/reference");
