import {
  AuthorityPayloadGet,
  AuthorityPayloadPost,
  AuthorityResponse,
  AuthorityResponseSearch,
} from "domain/entities/authority";

import axiosClient from "./axios";
import { createApiQuery, createSearchApiQuery } from "./queryUtils";

export interface AuthoritySearchQueryParams {
  name?: string;
}

export const authorityApi = {
  createAuthority: (data: AuthorityPayloadPost) =>
    axiosClient.post<AuthorityPayloadGet>("/hive/authority", data),
  updateAuthorityById: (id: string, data: AuthorityPayloadPost) =>
    axiosClient.put<AuthorityPayloadGet>(`/hive/authority/${id}`, data),
  deleteAuthorityById: (id: string) =>
    axiosClient.delete<AuthorityPayloadGet>(`/hive/authority/${id}`),
};

export const useAuthoritySearch = createSearchApiQuery<
  AuthorityResponseSearch,
  undefined,
  AuthoritySearchQueryParams | undefined
>("hive/authority");

export const useAuthorityById = createApiQuery<
  AuthorityResponse,
  { authorityId: string }
>("hive/authority/{authorityId}");
