import { ReactNode, useCallback, useMemo } from "react";

import useUserPreferencesStore from "hooks/useUserPreferences";

import { useUserProfileById } from "fetch/userProfile";

import { PermissionPayloadGet } from "domain/entities/permissions";

import { EntityType } from "globalTypes";

import { AtLeastOne } from "./typeUtils";

type ResourcePermissions = {
  [EntityType.ALARM]: "READ";
  [EntityType.ASSET]: "CREATE" | "READ" | "UPDATE" | "DELETE";
  [EntityType.ATTRIBUTE]: "CREATE" | "READ" | "UPDATE" | "DELETE";
  [EntityType.AUDIT]: "READ";
  [EntityType.AUTHORITY]: "CREATE" | "READ" | "UPDATE" | "DELETE";
  [EntityType.CLIENT]: "CREATE" | "READ" | "UPDATE" | "DELETE";
  [EntityType.COMMAND]: "CREATE" | "READ" | "UPDATE" | "DELETE" | "EXECUTE";
  [EntityType.COMMAND_LOG]: "READ";
  [EntityType.COMMAND_GROUP]:
    | "CREATE"
    | "READ"
    | "UPDATE"
    | "DELETE"
    | "EXECUTE";
  [EntityType.CONNECTOR]: "CREATE" | "READ" | "UPDATE" | "DELETE" | "TEST";
  [EntityType.CONNECTOR_LOG]: "READ";
  [EntityType.DASHBOARD]: "CREATE" | "READ" | "UPDATE" | "DELETE";
  [EntityType.DASHBOARD_VIEW]: "CREATE" | "READ" | "UPDATE" | "DELETE";
  [EntityType.DEVICE]: "CREATE" | "READ" | "UPDATE" | "DELETE" | "PROVISION";
  [EntityType.DEVICE_GROUP]: "CREATE" | "READ" | "UPDATE" | "DELETE";
  [EntityType.DEVICE_IMPORT_TEMPLATE]: "CREATE" | "READ" | "UPDATE" | "DELETE";
  [EntityType.DEVICE_IMPORT_TEMPLATE_FIELD]:
    | "CREATE"
    | "READ"
    | "UPDATE"
    | "DELETE";
  [EntityType.DEVICE_SPECIFICATION]: "CREATE" | "READ" | "UPDATE" | "DELETE";
  [EntityType.DEVICE_SPECIFICATION_FIELD]: "CREATE";
  [EntityType.DEVICE_PROFILE]:
    | "CREATE"
    | "READ"
    | "UPDATE"
    | "DELETE"
    | "PROVISION";
  [EntityType.ENTITY_VIEW]: "CREATE" | "READ" | "DELETE" | "UPDATE" | "RESULT";
  [EntityType.ERROR]: "READ";
  [EntityType.FAVOURITE]: "CREATE" | "READ" | "DELETE";
  [EntityType.GRANT]: "CREATE" | "READ" | "UPDATE" | "DELETE";
  [EntityType.INTEGRATION]: "CREATE" | "READ" | "UPDATE" | "DELETE";
  [EntityType.NODE]: "CREATE" | "READ" | "UPDATE" | "DELETE";
  [EntityType.OTA_PACKAGE]:
    | "CREATE"
    | "READ"
    | "UPDATE"
    | "DELETE"
    | "DOWNLOAD";
  [EntityType.PERMISSION]: "CREATE" | "READ" | "UPDATE" | "DELETE";
  [EntityType.PROJECT]: "CREATE" | "READ" | "UPDATE" | "DELETE";
  [EntityType.DEVICE_PROPERTY]: "CREATE" | "READ" | "UPDATE" | "DELETE";
  [EntityType.DEVICE_SPECIFICATION_PROPERTY]:
    | "CREATE"
    | "READ"
    | "UPDATE"
    | "DELETE";
  [EntityType.RELATION]: "CREATE" | "READ" | "UPDATE" | "DELETE";
  [EntityType.RESOURCE]: "READ";
  [EntityType.SYSTEM]: "READ" | "UPDATE";
  [EntityType.TELEMETRY]: "READ" | "GENERATE" | "SIMULATE";
  [EntityType.TENANT]: "CREATE" | "READ" | "UPDATE" | "DELETE";
  [EntityType.TENANT_TEMPLATE]: "CREATE" | "READ" | "UPDATE" | "DELETE";
  [EntityType.TRANSITION]: "CREATE" | "READ" | "UPDATE" | "DELETE";
  [EntityType.USER]: "CREATE" | "READ" | "UPDATE" | "DELETE";
  [EntityType.WIDGET]: "CREATE" | "READ" | "UPDATE" | "DELETE";
  [EntityType.WORKFLOW]: "CREATE" | "READ" | "UPDATE" | "DELETE";
  [EntityType.WORKFLOW_ELEMENT_LOG]: "READ";
  [EntityType.WORKFLOW_LOG]: "READ";
  [EntityType.WORKFLOW_VERSION]: "CREATE" | "READ" | "UPDATE" | "COPY";
  [EntityType.FIELD]: "CREATE" | "READ" | "UPDATE" | "DELETE";
};

export type RequiredPermissionDefinition = AtLeastOne<{
  [K in keyof ResourcePermissions]:
    | ResourcePermissions[K]
    | ResourcePermissions[K][];
}>;

export function hasResourcePermission(
  userPermissions: Pick<PermissionPayloadGet, "entityType" | "operations">[],
  requiredPermissions: RequiredPermissionDefinition,
) {
  return Object.entries(requiredPermissions).every((entry) => {
    const entityType = entry[0] as keyof ResourcePermissions;
    let operations: ResourcePermissions[keyof ResourcePermissions][];
    if (!Array.isArray(entry[1])) {
      operations = [entry[1]];
    } else {
      operations = entry[1];
    }
    return userPermissions.some((perm) => {
      return (
        perm.entityType === entityType &&
        operations.every((operation) =>
          perm.operations.some((it) => it === operation),
        )
      );
    });
  });
}

export function usePermissionGuard({ suspense = false } = {}) {
  const userId =
    useUserPreferencesStore((state) => state.user?.identifier.id) ?? "";
  const { data } = useUserProfileById({
    variables: {
      pathParams: {
        userId,
      },
    },
    staleTime: 60 * 60 * 1000, // 1 hour
    enabled: !!userId,
    suspense,
  });
  return useCallback(
    (permissions: RequiredPermissionDefinition) => {
      return hasResourcePermission(
        data?.payload.permissions ?? [],
        permissions,
      );
    },
    [data],
  );
}

export function useHasPermission(permissions: RequiredPermissionDefinition) {
  const hasPermission = usePermissionGuard();
  return useMemo(
    () => hasPermission(permissions),
    [permissions, hasPermission],
  );
}

export interface PermissionGuardProps {
  permissions: RequiredPermissionDefinition;
  children: ReactNode;
}

export function PermissionGuard({
  permissions,
  children,
}: Readonly<PermissionGuardProps>) {
  const hasPermission = useHasPermission(permissions);
  if (hasPermission) {
    return <>{children}</>;
  }
  return null;
}
