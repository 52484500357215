import { Paper, Box, Avatar } from "@mui/material";

import styled from "@emotion/styled";

export const TabPanelContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  display: ${({ hidden }) => (hidden ? "none" : "flex")};
  flex-direction: column;
`;

export const DetailedViewAvatar = styled(Avatar)`
  width: 70px;
  height: 70px;
  background-color: ${(props) => props.theme.palette.background.default};
`;

export const DetailedViewPaper = styled(Paper)`
  height: auto;
  display: flex;
  flex-direction: column;
`;

export const DetailedViewBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DetailedViewContent = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
`;

export const TitleAndImageContainer = styled(Box)`
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: start;
  gap: 1rem;
  align-items: center;
`;

export const PageTitleContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
`;

export const DetailsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem;
  width: 100%;
`;

export const DetailContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: auto;
  align-self: start;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
`;
