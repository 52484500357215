import Joi from "joi";

import { AggregationFunction } from "globalTypes";

import {
  RealTimeType,
  TimeframeType,
  TimeframeHistorical,
  TimeframeRealtime,
} from "../types";

export const timeframeDefaultValues = {
  type: TimeframeType.HISTORIC,
  from: new Date().toISOString(),
  to: new Date().toISOString(),
  aggregation: AggregationFunction.NONE,
  limit: 100,
  realTimeType: RealTimeType.PREDEFINED,
  period: 86400,
  interval: 10,
  dataPoints: 0,
};

export const timeframeValidationSchema = Joi.object<
  TimeframeHistorical | TimeframeRealtime
>().keys({
  type: Joi.string().valid(...Object.values(TimeframeType)),
  realTimeType: Joi.string()
    .valid(...Object.values(RealTimeType))
    .when("type", {
      is: TimeframeType.REALTIME,
      then: Joi.required(),
      otherwise: Joi.any().strip(),
    }),
  period: Joi.number()
    .when("type", {
      is: TimeframeType.REALTIME,
      otherwise: Joi.any().strip(),
    })
    .concat(
      Joi.number().when("realTimeType", {
        is: RealTimeType.PREDEFINED,
        then: Joi.required(),
        otherwise: Joi.any().strip(),
      }),
    ),
  interval: Joi.number().max(Joi.ref("period")),
  limit: Joi.number(),
  aggregation: Joi.string()
    .required()
    .valid(...Object.values(AggregationFunction)),
  from: Joi.string().when("type", {
    is: TimeframeType.HISTORIC,
    then: Joi.required(),
    otherwise: Joi.when("realTimeType", {
      is: RealTimeType.CUSTOM,
      then: Joi.required(),
      otherwise: Joi.any().strip(),
    }),
  }),
  to: Joi.string().when("type", {
    is: TimeframeType.HISTORIC,
    then: Joi.required(),
    otherwise: Joi.when("realTimeType", {
      is: RealTimeType.CUSTOM,
      then: Joi.required(),
      otherwise: Joi.string().strip(),
    }),
  }),
  dataPoints: Joi.number().empty(""),
});
export const predefinedTimeFrameValues: Array<{
  label: string;
  value: number;
}> = [
  {
    label: "10 seconds",
    value: 10,
  },
  {
    label: "30 seconds",
    value: 30,
  },
  {
    label: "1 minute",
    value: 60,
  },
  {
    label: "2 minutes",
    value: 120,
  },
  {
    label: "5 minutes",
    value: 300,
  },
  {
    label: "10 minutes",
    value: 600,
  },
  {
    label: "15 minutes",
    value: 900,
  },
  {
    label: "30 minutes",
    value: 1800,
  },
  {
    label: "1 hour",
    value: 3600,
  },
  {
    label: "2 hours",
    value: 7200,
  },
  {
    label: "10 hours",
    value: 36000,
  },
  {
    label: "12 hours",
    value: 43200,
  },
  {
    label: "1 day",
    value: 86400,
  },
  {
    label: "2 days",
    value: 172800,
  },
  {
    label: "5 days",
    value: 432000,
  },
  {
    label: "7 days",
    value: 604800,
  },
  {
    label: "10 days",
    value: 864000,
  },
  {
    label: "15 days",
    value: 1296000,
  },
  {
    label: "30 days",
    value: 2592000,
  },
];
