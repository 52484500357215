import { jwtDecode } from "jwt-decode";

import { JwtData } from "types/jwt";

const getDecodedToken = () => {
  const accessToken = getAccessToken();
  if (accessToken) {
    const decodedToken: { tenantId: string; userId: string } =
      jwtDecode(accessToken);

    return decodedToken;
  }
  return undefined;
};

const setJwtData = (jwtData: JwtData) => {
  window.localStorage.setItem("jwtData", JSON.stringify(jwtData));
};

const clearJwtData = () => window.localStorage.removeItem("jwtData");

const getJwtDataByKey = (key: keyof JwtData) => {
  const data = window.localStorage.getItem("jwtData");
  if (data) {
    const parsedData: JwtData = JSON.parse(data);
    return parsedData[key] as string;
  }
  return undefined;
};

const getAccessToken = (
  accessToken: string | undefined = getJwtDataByKey("access_token"),
): string | undefined => {
  return accessToken;
};

const getRefreshToken = (
  refreshToken: string | undefined = getJwtDataByKey("refresh_token"),
) => {
  return refreshToken;
};

export {
  getDecodedToken,
  getAccessToken,
  getRefreshToken,
  setJwtData,
  clearJwtData,
};
