import { useCallback, useEffect, useMemo } from "react";

import {
  MutationFunction,
  MutationMeta,
  MutationOptions,
  QueryClient,
  QueryFunctionContext,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";
import {
  createInfiniteQuery,
  createQuery,
  CreateQueryOptions,
  QueryHook,
} from "react-query-kit";

import { AxiosError, AxiosResponse } from "axios";

import { usePagination } from "hooks/usePagination";
import useSnackbar from "hooks/useSnackbar";

import { Optional } from "utils/typeUtils";

import {
  BadRequest,
  BaseResponseSearch,
  PayloadGet,
  ResponseWrapper,
  ResponseWrapperSearch,
  ResultType,
  SearchType,
  SnackbarMessageType,
  SortingDirection,
} from "globalTypes";

import axiosClient from "./axios";

export interface SearchParams
  extends Record<string, string | number | undefined> {
  searchType?: SearchType;
  resultType?: ResultType;
  page?: number;
  entitiesPerPage?: number;
  textSearch?: string;
  sortBy?: string;
  sortingDirection?: SortingDirection;
}

// interface extends a map, so only field names are important, anything can actually be passed in
export interface MutationMetaOptions extends MutationMeta {
  dataKeys: any[];
  invalidateKeys: any[];
}

export type QueryPathVariables<TPath> =
  TPath extends Record<string, string> ? { pathParams: TPath } : {} | undefined;

export type QueryQueryVariables<TQuery> = TQuery extends undefined
  ?
      | {
          queryParams?: Record<string, any>;
          headerParams?: Record<"tenantId", Optional<string>>;
        }
      | undefined
  : {
      queryParams: TQuery;
      headerParams?: Record<"tenantId", Optional<string>>;
    };

export type QueryVariables<TPath, TQuery> = QueryPathVariables<TPath> &
  QueryQueryVariables<TQuery>;

export function createApiQuery<
  TResponse,
  TPath extends Record<string, string> | undefined = undefined,
  TQuery = undefined,
>(path: string, staleTime?: number, { suspense = false } = {}) {
  return createQuery<
    TResponse,
    QueryVariables<TPath, TQuery>,
    AxiosError<BadRequest>
  >({
    primaryKey: path,
    queryFn: queryFunction as CreateQueryOptions<
      TResponse,
      QueryVariables<TPath, TQuery>,
      AxiosError<BadRequest>
    >["queryFn"],
    staleTime: staleTime,
    suspense,
  });
}

export function createApiQueryInfinite<
  TResponse,
  TPath extends Record<string, string> | undefined = undefined,
  TQuery = undefined,
  TPageParam = number,
>(
  path: string,
  {
    getNextPageParam,
    getPreviousPageParam,
  }: {
    getNextPageParam: (
      lastPage: TResponse,
      allPages: TResponse[],
    ) => TPageParam | undefined;
    getPreviousPageParam?: (
      firstPage: TResponse,
      allPages: TResponse[],
    ) => TPageParam | undefined;
  },
) {
  return createInfiniteQuery<
    TResponse,
    QueryVariables<TPath, TQuery>,
    AxiosError<BadRequest>,
    TPageParam
  >({
    primaryKey: `${path}|infinite`,
    queryFn: queryFunction as CreateQueryOptions<
      TResponse,
      QueryVariables<TPath, TQuery>,
      AxiosError<BadRequest>
    >["queryFn"],
    getNextPageParam,
    getPreviousPageParam,
  });
}

export function createSearchApiQuery<
  TResponse extends ResponseWrapperSearch,
  TPath extends Record<string, string> | undefined = undefined,
  TQuery = undefined,
>(path: string) {
  const useSearchQuery = function <TQueryData = TResponse>(
    paginationState: Partial<
      Pick<
        ReturnType<typeof usePagination>,
        "setpaginationProperties" | "getSearchParams"
      >
    >,
    variables: QueryVariables<TPath, TQuery>,
    options?: Omit<
      UseQueryOptions<
        TResponse,
        AxiosError<BadRequest>,
        TQueryData,
        [string, QueryVariables<TPath, TQuery>, SearchParams | undefined]
      >,
      "queryFn" | "queryKey" | "select"
    >,
  ) {
    const queryClient = useQueryClient();
    const paginationSearchParams = paginationState.getSearchParams?.();
    const searchParams: SearchParams = useMemo(
      () => ({
        searchType: SearchType.STANDARD,
        resultType: ResultType.COMPLETE,
        ...paginationSearchParams,
      }),
      [paginationSearchParams],
    );
    const queryReturn = useQuery<
      TResponse,
      AxiosError<BadRequest>,
      TQueryData,
      [string, QueryVariables<TPath, TQuery>, SearchParams | undefined]
    >({
      queryKey: [path, variables, searchParams],
      queryFn: queryFunction,
      keepPreviousData: true,
      ...options,
    });

    const setPaginationProperties = paginationState.setpaginationProperties;

    useEffect(() => {
      if (queryReturn.data && setPaginationProperties) {
        const properties = {
          numberOfRecords: (queryReturn.data as any).numberOfRecords,
          numberOfPages: (queryReturn.data as any).numberOfPages,
        };
        if (
          properties.numberOfPages == null ||
          properties.numberOfRecords == null
        ) {
          console.error(
            "numberOfPages or numberOfRecords is missing from search query",
          );
        }
        setPaginationProperties(properties);
      }
    }, [queryReturn.data, setPaginationProperties]);

    const setQueryData = useCallback(
      (updater: (data: TResponse | undefined) => TResponse | undefined) => {
        setSearchQueryData(
          queryClient,
          useSearchQuery,
          searchParams,
          variables,
          updater,
        );
      },
      [queryClient, searchParams, variables],
    );

    return { ...queryReturn, setQueryData };
  };

  useSearchQuery.queryFn = queryFunction as typeof queryFunction<
    TResponse,
    TPath,
    TQuery
  >;

  useSearchQuery.getKey = (
    searchParams: SearchParams | undefined,
    variables: QueryVariables<TPath, TQuery>,
  ): [string, QueryVariables<TPath, TQuery>, SearchParams | undefined] => [
    path,
    variables,
    searchParams,
  ];

  return useSearchQuery;
}

async function queryFunction<
  TResponse,
  TPath extends Record<string, string> | undefined,
  TQuery,
>(
  context: QueryFunctionContext<
    | [string, QueryVariables<TPath, TQuery>]
    | [string, QueryVariables<TPath, TQuery>, SearchParams | undefined]
  >,
): Promise<TResponse> {
  let path = context.queryKey[0];
  const variables = context.queryKey[1];
  let searchParams = context.queryKey[2];
  let queryParams = variables?.queryParams;
  const headerParams = variables?.headerParams;
  if (variables && "pathParams" in variables) {
    path = path.replace(
      /(\{.*})/g,
      (match) =>
        variables.pathParams[match.substring(1, match.length - 1)] ?? match,
    );
  }
  path = path.replace("|infinite", "");
  if (context.pageParam) {
    if (!searchParams) {
      searchParams = {};
    }
    searchParams.page = context.pageParam;
  }
  const response = await axiosClient.get<TResponse>(path, {
    params: { ...queryParams, ...searchParams },
    signal: context.signal,
    headers: headerParams,
  });
  return response.data;
}

export async function fetchQueryData<
  TResponse,
  TPath extends Record<string, string> | undefined,
  TQuery,
  TData = TResponse,
>(
  queryClient: QueryClient,
  hook: QueryHook<
    TResponse,
    QueryVariables<TPath, TQuery>,
    AxiosError<BadRequest>
  >,
  variables: Parameters<typeof hook.getKey>[0],
) {
  return queryClient.fetchQuery<
    TResponse,
    AxiosError<BadRequest>,
    TData,
    ReturnType<typeof hook.getKey>
  >(
    // @ts-ignore - getKey has a weird generic type that does not align with queryFn type
    {
      queryKey: hook.getKey(variables),
      queryFn: hook.queryFn,
      queryKeyHashFn: hook.queryKeyHashFn,
    },
  );
}

export async function fetchSearchQueryData<
  TResponse,
  TPath extends Record<string, string> | undefined = undefined,
  TQuery = undefined,
  HookVars extends QueryVariables<TPath, TQuery> = QueryVariables<
    TPath,
    TQuery
  >,
>(
  queryClient: QueryClient,
  hook: {
    queryFn: typeof queryFunction<TResponse, TPath, TQuery>;
    getKey: (
      searchParams: SearchParams | undefined,
      variables: QueryVariables<TPath, TQuery>,
    ) => readonly [
      string,
      QueryVariables<TPath, TQuery>,
      SearchParams | undefined,
    ];
  },
  searchParams: SearchParams | undefined,
  variables: HookVars,
): Promise<TResponse> {
  // @ts-ignore - getKey has a weird generic type that does not align with queryFn type
  return queryClient.fetchQuery({
    queryKey: hook.getKey(searchParams, variables),
    queryFn: queryFunction,
  });
}

export function setSearchQueryData<
  TResponse,
  TPath extends Record<string, string> | undefined = undefined,
  TQuery = undefined,
  HookVars extends QueryVariables<TPath, TQuery> = QueryVariables<
    TPath,
    TQuery
  >,
>(
  queryClient: QueryClient,
  hook: {
    queryFn: typeof queryFunction<TResponse, TPath, TQuery>;
    getKey: (
      searchParams: SearchParams | undefined,
      variables: QueryVariables<TPath, TQuery>,
    ) => readonly [
      string,
      QueryVariables<TPath, TQuery>,
      SearchParams | undefined,
    ];
  },
  searchParams: SearchParams | undefined,
  variables: HookVars,
  updater: (data: TResponse | undefined) => TResponse | undefined,
) {
  return queryClient.setQueryData(
    hook.getKey(searchParams, variables),
    updater,
  );
}

export function setQueryData<
  TResponse,
  TPath extends Record<string, string> | undefined,
  TQuery,
>(
  queryClient: QueryClient,
  hook: QueryHook<
    TResponse,
    QueryVariables<TPath, TQuery>,
    AxiosError<BadRequest>
  >,
  variables: Parameters<typeof hook.getKey>[0],
  updater: (data: TResponse | undefined) => TResponse | undefined,
) {
  return queryClient.setQueryData(hook.getKey(variables), updater);
}

export function useApiMutation<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(
  mutationFn: MutationFunction<TData, TVariables>,
  successMessage?:
    | string
    | ((
        data: TData,
        variables: TVariables,
        context: TContext | undefined,
      ) => string),
  options?: Omit<
    MutationOptions<TData, TError, TVariables, TContext>,
    "mutationFn" | "mutationKey"
  >,
) {
  const { enqueueSnackbar, enqueueMutationErrorSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn,
    ...options,
    onSuccess: (data, variables, context) => {
      if (successMessage) {
        if (successMessage instanceof Function) {
          successMessage = successMessage(data, variables, context);
        }
        enqueueSnackbar(successMessage, {
          variant: SnackbarMessageType.SUCCESS,
        });
      }
      const mutationMetaOptions = options?.meta as MutationMetaOptions;
      if (mutationMetaOptions?.dataKeys) {
        queryClient.setQueryData(
          // probably wrongly typed as data here is Axios response, not JSON received from backed
          mutationMetaOptions?.dataKeys,
          (data as AxiosResponse<TData>).data,
        );
      }
      if (
        mutationMetaOptions?.invalidateKeys &&
        mutationMetaOptions?.invalidateKeys.length > 0
      ) {
        queryClient.invalidateQueries(mutationMetaOptions.invalidateKeys);
      }
      options?.onSuccess?.(data, variables, context);
    },
    onError: (error, variables, context) => {
      enqueueMutationErrorSnackbar(error);
      options?.onError?.(error, variables, context);
    },
  });
}

/**
 * This hook is used to preload entities from search response to the by id response.
 * @param searchData Search response
 * @param hook Get by id hook
 * @param mapVariables Function to map the entity from search response to the variables of the get by id hook
 */
export function useSyncEntitiesFromSearch<
  THookRes extends ResponseWrapper,
  TRes extends BaseResponseSearch,
  TPath extends Record<string, string> | undefined,
  TQuery,
>(
  searchData: TRes | undefined,
  hook: QueryHook<
    THookRes,
    QueryVariables<TPath, TQuery>,
    AxiosError<BadRequest>
  >,
  mapVariables: (entity: PayloadGet) => Parameters<typeof hook.getKey>[0],
) {
  const queryClient = useQueryClient();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const mapCallback = useCallback(mapVariables, []);
  useEffect(() => {
    if (searchData) {
      searchData.payload.forEach((entity) => {
        // @ts-ignore
        setQueryData(queryClient, hook, mapCallback(entity), (prev) => {
          if (prev) {
            return prev;
          }
          return {
            responseCodeObject: searchData.responseCodeObject,
            successful: searchData.successful,
            responseDetail: searchData.responseDetail,
            payload: entity,
            responseCode: searchData.responseCode,
          };
        });
      });
    }
  }, [searchData, hook, mapCallback, queryClient]);
}
