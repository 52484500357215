import {
  Bell,
  Book,
  Cloud,
  Codesandbox,
  Database,
  DownloadCloud,
  Layout,
  Monitor,
  Sliders,
  Tablet,
} from "react-feather";
import { createBrowserRouter, Params } from "react-router-dom";

// All pages that rely on 3rd party components (other than MUI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size
// Layouts

import {
  Settings,
  People,
  MenuBook,
  Person,
  Smartphone,
  AppSettingsAlt,
  CloudCircle,
  WebAsset,
  PermIdentity,
  Schema,
  InstallMobile,
  OfflineShare,
} from "@mui/icons-material";

import { TFunction } from "i18next";

import async from "./components/Async";
import { RouteBreadcrumbProps } from "./components/routeBreadcrumbs/types";
import {
  SkeletonDetails,
  SkeletonOverview,
  SkeletonTable,
} from "./components/skeletons";

// Error pages
import { Root } from "layouts/Root";

import Page404 from "pages/error/Page404";
import Page500 from "pages/error/Page500";

import { useAssetById } from "fetch/asset";
import { useAuthorityById } from "fetch/authority";
import { useDashboardById } from "fetch/dashboard";
import { useDeviceById } from "fetch/device";
import { useDeviceImportTemplateById } from "fetch/deviceImportTemplate";
import { useDeviceSpecificationById } from "fetch/deviceSpecification";
import { useIntegrationById } from "fetch/integration";
import { useOtaById } from "fetch/ota";
import { useProjectById } from "fetch/project";
import { useTenantById } from "fetch/tenant";
import { useUserById } from "fetch/user";
import { useUserProfileById } from "fetch/userProfile";

import { AuthorityResponse } from "domain/entities/authority";
import { DashboardResponse } from "domain/entities/dashboard";
import { ProjectResponse } from "domain/entities/project";
import { TenantResponse } from "domain/entities/tenant";
import { UserResponse } from "domain/entities/user";

// Pages
const AlarmsPage = async(
  () => import("./pages/alarms/AlarmsPage"),
  <SkeletonTable />,
);
const ProjectsPage = async(
  () => import("pages/projects/ProjectsPage"),
  <SkeletonTable />,
);
const TenantsPage = async(
  () => import("pages/tenants/TenantsPage"),
  <SkeletonTable />,
);
const TenantPage = async(() => import("pages/tenants"), <SkeletonDetails />);
const Overview = async(() => import("./pages/overview"), <SkeletonOverview />);
const ProjectPage = async(
  () => import("components/project"),
  <SkeletonDetails />,
);

const SettingsPage = async(() => import("./pages/settings"), <SkeletonTable />);
const OtasPage = async(() => import("pages/ota/OtaPage"), <SkeletonTable />);
const OtaPage = async(() => import("components/ota/Ota"), <SkeletonDetails />);

const DeviceSpecificationPage = async(
  () => import("./pages/deviceSpecification/DeviceSpecificationPage"),
  <SkeletonTable />,
);
const DeviceSpecification = async(
  () => import("components/deviceSpecification"),
  <SkeletonDetails />,
);
const DashboardsPage = async(
  () => import("./pages/dashboard/DashboardPage"),
  <SkeletonTable />,
);
const DashboardPage = async(() => import("components/dashboard"));
const AuditPage = async(
  () => import("./pages/audit/AuditPage"),
  <SkeletonTable />,
);
const DevicesPage = async(
  () => import("./pages/device/DevicePage"),
  <SkeletonTable />,
);
const DevicePage = async(
  () => import("components/device"),
  <SkeletonDetails />,
);
const AssetsPage = async(
  () => import("./pages/assets/AssetsPage"),
  <SkeletonTable />,
);
const AssetDetailPage = async(
  () => import("./pages/assets/AssetDetailPage"),
  <SkeletonDetails />,
);
const UsersPage = async(
  () => import("./pages/users/UsersPage"),
  <SkeletonTable />,
);
const UserPage = async(() => import("components/user"), <SkeletonDetails />);
const IntegrationsPage = async(
  () => import("./pages/integration/IntegrationPage"),
  <SkeletonTable />,
);
const IntegrationPage = async(
  () => import("components/integration"),
  <SkeletonDetails />,
);
const AuthoritiesPage = async(
  () => import("./pages/authority/AuthoritiesPage"),
  <SkeletonTable />,
);
const AuthorityPage = async(
  () => import("./pages/authority/AuthorityDetailPage"),
  <SkeletonDetails />,
);
const WorkflowsPage = async(
  () => import("./pages/workflow/WorkflowsPage"),
  <SkeletonTable />,
);
const WorkflowEditorPage = async(
  () => import("./pages/workflow/WorkflowEditorPage"),
  <SkeletonDetails />,
);
const DeviceImportTemplatesPage = async(
  () => import("./pages/templates/DeviceImportTemplates"),
  <SkeletonTable />,
);
const DeviceImportTemplatePage = async(
  () => import("components/deviceImportTemplate/DeviceImportTemplate"),
  <SkeletonDetails />,
);
const TenantSelectPage = async(
  () => import("pages/tenantSelector/TenantSelector"),
  <SkeletonDetails />,
);
const UserProfilePage = async(
  () => import("pages/userProfile/UserProfilePage"),
  <SkeletonDetails />,
);

export type CrumbFunction = <TData>(
  t: TFunction,
  params: Params,
) => RouteBreadcrumbProps<TData>;

export interface RouteHandle {
  crumb?: CrumbFunction;
}

const getRouter = () =>
  createBrowserRouter([
    {
      path: "/",
      element: <Root />,
      errorElement: <Page500 />,
      children: [
        {
          path: "profile",
          element: <UserProfilePage />,
          errorElement: <Page500 />,
          handle: {
            crumb: (t, params) => ({
              dataQuery: {
                queryKey: useUserProfileById.getKey({
                  pathParams: {
                    userId: params.id!,
                  },
                }),
                queryFn: useUserProfileById.queryFn,
                queryKeyHashFn: useUserProfileById.queryKeyHashFn,
              },
              label: (data?: UserResponse) =>
                data?.payload.name.firstName.concat(
                  " ",
                  data?.payload.name.lastName,
                ) ?? t("page.users.detailedView.title"),
              icon: <Person />,
            }),
          } as RouteHandle,
          children: [
            {
              path: "*",
              element: <Page404 />,
            },
          ],
        },
        {
          path: `system`,
          children: [
            {
              path: "tenants",
              handle: {
                crumb: (t, params) => ({
                  label: t("page.tenants.title"),
                  icon: <People />,
                }),
              } as RouteHandle,
              children: [
                {
                  index: true,
                  element: <TenantsPage />,
                  errorElement: <Page500 />,
                },
                {
                  path: ":id",
                  element: <TenantPage />,
                  errorElement: <Page500 />,
                  handle: {
                    crumb: (t, params) => ({
                      dataQuery: {
                        queryKey: useTenantById.getKey({
                          pathParams: {
                            tenantId: params.id!,
                          },
                        }),
                        queryFn: useTenantById.queryFn,
                        queryKeyHashFn: useTenantById.queryKeyHashFn,
                      },
                      label: (data?: TenantResponse) =>
                        data?.payload.name ??
                        t("page.tenants.detailedView.title"),
                      icon: <Person />,
                    }),
                  } as RouteHandle,
                },
              ],
            },
            {
              path: "settings",
              element: <SettingsPage />,
              errorElement: <Page500 />,
              handle: {
                crumb: (t, params) => ({
                  label: t("page.settingsPage.title"),
                  icon: <Settings />,
                }),
              } as RouteHandle,
            },
          ],
        },
        {
          path: `tenant/:activeTenantId`,
          children: [
            {
              path: "overview",
              element: <Overview type={"tenant"} />,
              errorElement: <Page500 />,
              handle: {
                crumb: (t, params) => ({
                  label: t("page.tenantOverview.title"),
                }),
              } as RouteHandle,
            },
            {
              path: "project",
              handle: {
                crumb: (t, params) => ({
                  label: t("page.project.title"),
                  icon: <Book />,
                }),
              } as RouteHandle,
              children: [
                {
                  index: true,
                  element: <ProjectsPage />,
                  errorElement: <Page500 />,
                },
                {
                  path: ":id",
                  element: <ProjectPage />,
                  errorElement: <Page500 />,
                  handle: {
                    crumb: (t, params) => ({
                      dataQuery: {
                        queryKey: useProjectById.getKey({
                          pathParams: {
                            projectId: params.id!,
                          },
                        }),
                        queryFn: useProjectById.queryFn,
                        queryKeyHashFn: useProjectById.queryKeyHashFn,
                      },
                      label: (data?: ProjectResponse) =>
                        data?.payload.name ??
                        t("page.project.detailedView.title"),
                      icon: <MenuBook />,
                    }),
                  } as RouteHandle,
                },
              ],
            },

            {
              path: "tenants",
              element: <TenantsPage />,
              errorElement: <Page500 />,
            },
            {
              path: "users",
              handle: {
                crumb: (t, params) => ({
                  label: t("page.users.title"),
                  icon: <People />,
                }),
              } as RouteHandle,
              children: [
                {
                  index: true,
                  element: <UsersPage />,
                  errorElement: <Page500 />,
                },
                {
                  path: ":id",
                  element: <UserPage />,
                  errorElement: <Page500 />,
                  handle: {
                    crumb: (t, params) => ({
                      dataQuery: {
                        queryKey: useUserById.getKey({
                          pathParams: {
                            userId: params.id!,
                          },
                        }),
                        queryFn: useUserById.queryFn,
                        queryKeyHashFn: useUserById.queryKeyHashFn,
                      },
                      label: (data?: UserResponse) =>
                        data?.payload.name.firstName.concat(
                          " ",
                          data?.payload.name.lastName,
                        ) ?? t("page.users.detailedView.title"),
                      icon: <Person />,
                    }),
                  } as RouteHandle,
                },
              ],
            },
            {
              path: "authorities",
              handle: {
                crumb: (t, params) => ({
                  label: t("page.authorities.title"),
                  icon: <PermIdentity />,
                }),
              } as RouteHandle,
              children: [
                {
                  index: true,
                  element: <AuthoritiesPage />,
                  errorElement: <Page500 />,
                },
                {
                  path: ":id",
                  element: <AuthorityPage />,
                  errorElement: <Page500 />,
                  handle: {
                    crumb: (t, params) => ({
                      dataQuery: {
                        queryKey: useAuthorityById.getKey({
                          pathParams: {
                            authorityId: params.id!,
                          },
                        }),
                        queryFn: useAuthorityById.queryFn,
                        queryKeyHashFn: useAuthorityById.queryKeyHashFn,
                      },
                      label: (data?: AuthorityResponse) =>
                        data?.payload.name ??
                        t("page.authorities.detailedView.title"),
                      icon: <Person />,
                    }),
                  } as RouteHandle,
                },
              ],
            },
            {
              path: "audit",
              element: <AuditPage type="tenant" />,
              errorElement: <Page500 />,
              handle: {
                crumb: (t, params) => ({
                  label: t("page.audit.title.tenant"),
                  icon: <Database />,
                }),
              } as RouteHandle,
            },
          ],
        },
        {
          path: `project/:activeProjectId`,
          children: [
            {
              path: "overview",
              element: <Overview type={"project"} />,
              errorElement: <Page500 />,
            },
            {
              path: "dashboard",
              handle: {
                crumb: (t, params) => ({
                  label: t("page.dashboard.title"),
                  icon: <Layout />,
                }),
              } as RouteHandle,
              children: [
                {
                  index: true,
                  element: <DashboardsPage />,
                  errorElement: <Page500 />,
                },
                {
                  path: ":id",
                  element: <DashboardPage />,
                  errorElement: <Page500 />,
                  handle: {
                    crumb: (t, params) => ({
                      dataQuery: {
                        queryKey: useDashboardById.getKey({
                          pathParams: {
                            dashboardId: params.id!,
                          },
                        }),
                        queryFn: useDashboardById.queryFn,
                        queryKeyHashFn: useDashboardById.queryKeyHashFn,
                      },
                      label: (data?: DashboardResponse) =>
                        data?.payload.name ?? t("component.dashboard.title"),
                      icon: <Monitor />,
                    }),
                  } as RouteHandle,
                },
              ],
            },
            {
              path: "device",
              handle: {
                crumb: (t, params) => ({
                  label: t("page.device.title"),
                  icon: <Tablet />,
                }),
              } as RouteHandle,
              children: [
                {
                  index: true,
                  element: <DevicesPage />,
                  errorElement: <Page500 />,
                },
                {
                  path: ":id",
                  element: <DevicePage />,
                  errorElement: <Page500 />,
                  handle: {
                    crumb: (t, params) => ({
                      dataQuery: {
                        queryKey: useDeviceById.getKey({
                          pathParams: {
                            deviceId: params.id!,
                          },
                        }),
                        queryFn: useDeviceById.queryFn,
                        queryKeyHashFn: useDeviceById.queryKeyHashFn,
                      },
                      label: (data?: UserResponse) =>
                        data?.payload.name ??
                        t("page.device.detailedView.title"),
                      icon: <Smartphone />,
                    }),
                  } as RouteHandle,
                },
              ],
            },
            {
              path: "specifications",
              handle: {
                crumb: (t, params) => ({
                  label: t("page.deviceSpecification.title"),
                  icon: <Sliders />,
                }),
              } as RouteHandle,
              children: [
                {
                  index: true,
                  element: <DeviceSpecificationPage />,
                  errorElement: <Page500 />,
                },
                {
                  path: ":id",
                  element: <DeviceSpecification />,
                  errorElement: <Page500 />,
                  handle: {
                    crumb: (t, params) => ({
                      dataQuery: {
                        queryKey: useDeviceSpecificationById.getKey({
                          pathParams: {
                            specificationId: params.id!,
                          },
                        }),
                        queryFn: useDeviceSpecificationById.queryFn,
                        queryKeyHashFn:
                          useDeviceSpecificationById.queryKeyHashFn,
                      },
                      label: (data?: UserResponse) =>
                        data?.payload.name ??
                        t("page.deviceSpecification.detailedView.title"),
                      icon: <AppSettingsAlt />,
                    }),
                  } as RouteHandle,
                },
              ],
            },
            {
              path: "alarms",
              element: <AlarmsPage />,
              errorElement: <Page500 />,
              handle: {
                crumb: (t, params) => ({
                  label: t("page.alarms.title"),
                  icon: <Bell />,
                }),
              } as RouteHandle,
            },
            {
              path: "integrations",
              handle: {
                crumb: (t, params) => ({
                  label: t("page.integration.title"),
                  icon: <Cloud />,
                }),
              } as RouteHandle,
              children: [
                {
                  index: true,
                  element: <IntegrationsPage />,
                  errorElement: <Page500 />,
                },
                {
                  path: ":id",
                  element: <IntegrationPage />,
                  errorElement: <Page500 />,
                  handle: {
                    crumb: (t, params) => ({
                      dataQuery: {
                        queryKey: useIntegrationById.getKey({
                          pathParams: {
                            integrationId: params.id!,
                          },
                        }),
                        queryFn: useIntegrationById.queryFn,
                        queryKeyHashFn: useIntegrationById.queryKeyHashFn,
                      },
                      label: (data?: UserResponse) =>
                        data?.payload.name ??
                        t("page.integration.detailedView.title"),
                      icon: <CloudCircle />,
                    }),
                  } as RouteHandle,
                },
              ],
            },
            {
              path: "ota",
              handle: {
                crumb: (t, params) => ({
                  label: t("page.ota.title"),
                  icon: <DownloadCloud />,
                }),
              } as RouteHandle,
              children: [
                {
                  index: true,
                  element: <OtasPage />,
                  errorElement: <Page500 />,
                },
                {
                  path: ":id",
                  element: <OtaPage />,
                  errorElement: <Page500 />,
                  handle: {
                    crumb: (t, params) => ({
                      dataQuery: {
                        queryKey: useOtaById.getKey({
                          pathParams: {
                            otaId: params.id!,
                          },
                        }),
                        queryFn: useOtaById.queryFn,
                        queryKeyHashFn: useOtaById.queryKeyHashFn,
                      },
                      label: (data?: UserResponse) =>
                        data?.payload.name ?? t("page.ota.detailedView.title"),
                      icon: <Cloud />,
                    }),
                  } as RouteHandle,
                },
              ],
            },
            {
              path: "assets",
              handle: {
                crumb: (t, params) => ({
                  label: t("page.assets.title"),
                  icon: <Codesandbox />,
                }),
              } as RouteHandle,
              children: [
                {
                  index: true,
                  element: <AssetsPage />,
                  errorElement: <Page500 />,
                },
                {
                  path: ":id",
                  element: <AssetDetailPage />,
                  errorElement: <Page500 />,
                  handle: {
                    crumb: (t, params) => ({
                      dataQuery: {
                        queryKey: useAssetById.getKey({
                          pathParams: {
                            assetId: params.id!,
                          },
                        }),
                        queryFn: useAssetById.queryFn,
                        queryKeyHashFn: useAssetById.queryKeyHashFn,
                      },
                      label: (data?: UserResponse) =>
                        data?.payload.name ??
                        t("page.asset.detailedView.title"),
                      icon: <WebAsset />,
                    }),
                  } as RouteHandle,
                },
              ],
            },
            {
              path: "audit",
              element: <AuditPage type="project" />,
              errorElement: <Page500 />,
              handle: {
                crumb: (t, params) => ({
                  label: t("page.audit.title.project"),
                  icon: <Database />,
                }),
              } as RouteHandle,
            },
            {
              path: "workflows",
              handle: {
                crumb: (t, params) => ({
                  label: t("page.workflow.title"),
                  icon: <Schema />,
                }),
              } as RouteHandle,
              children: [
                {
                  index: true,
                  element: <WorkflowsPage />,
                  errorElement: <Page500 />,
                },
                {
                  path: ":id",
                  element: <WorkflowEditorPage />,
                  errorElement: <Page500 />,
                },
              ],
            },
            {
              path: "templates",
              children: [
                {
                  path: "deviceImport",
                  handle: {
                    crumb: (t: TFunction) => ({
                      label: t("page.templates.title"),
                      icon: <OfflineShare />,
                    }),
                  },
                  children: [
                    {
                      index: true,
                      element: <DeviceImportTemplatesPage />,
                      errorElement: <Page500 />,
                    },
                    {
                      path: ":id",
                      element: <DeviceImportTemplatePage />,
                      errorElement: <Page500 />,
                      handle: {
                        crumb: (t, params) => ({
                          dataQuery: {
                            queryKey: useDeviceImportTemplateById.getKey({
                              pathParams: {
                                deviceImportTemplateId: params.id!,
                              },
                            }),
                            queryFn: useDeviceImportTemplateById.queryFn,
                            queryKeyHashFn:
                              useDeviceImportTemplateById.queryKeyHashFn,
                          },
                          label: (data?: UserResponse) =>
                            data?.payload.name ??
                            t("page.templates.detailedView.title"),
                          icon: <InstallMobile />,
                        }),
                      } as RouteHandle,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: "*",
          element: <Page404 />,
        },
      ],
    },
    {
      path: "/tenantSelect",
      element: <TenantSelectPage />,
      errorElement: <Page500 />,
      children: [
        {
          path: "*",
          element: <Page404 />,
        },
      ],
    },
  ]);

export default getRouter;
