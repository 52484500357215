import React from "react";

import { withTheme } from "@emotion/react";

import { Menu as MenuIcon } from "@mui/icons-material";
import {
  AppBar as MuiAppBar,
  Grid,
  IconButton as MuiIconButton,
  Toolbar,
} from "@mui/material";

import styled from "@emotion/styled";

import NavbarBreadcrumb from "./NavbarBreadcrumb";
import NavbarLanguagesDropdown from "./NavbarLanguagesDropdown";
import NavbarNotificationsDropdown from "./NavbarNotificationsDropdown";
import NavbarTenantSelect from "./NavbarTenantSelect";
import NavbarTheme from "./NavbarTheme";
import NavbarUserDropdown from "./NavbarUserDropdown";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Offset = styled("div")(({ theme }) => theme.mixins.toolbar);

type NavbarProps = {
  onDrawerToggle: React.MouseEventHandler<HTMLElement>;
};

const Navbar: React.FC<NavbarProps> = ({ onDrawerToggle }) => {
  return (
    <>
      <AppBar position="fixed" elevation={0}>
        <Toolbar>
          <Grid
            container
            alignItems="center"
            sx={{ marginLeft: { lg: "258px" } }}
          >
            <Grid item sx={{ display: { xs: "block", lg: "none" } }}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
                size="large"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item xs sx={{ paddingLeft: { lg: "38px" } }}>
              <NavbarBreadcrumb />
            </Grid>
            <Grid item>
              <NavbarTenantSelect />
              <NavbarTheme />
              <NavbarNotificationsDropdown />
              <NavbarLanguagesDropdown />
              <NavbarUserDropdown />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Offset />
    </>
  );
};

export default withTheme(Navbar);
