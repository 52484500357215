import { Option, CommandLogResultStatus } from "globalTypes";

import type { NamedColorspace } from "@textea/json-viewer";

// Request params
export const PAGE = "page";
export const ENTITIES_PER_PAGE = "entitiesPerPage";
export const SEARCH_TYPE = "searchType";
export const RESULT_TYPE = "resultType";
export const TEXT_SEARCH = "textSearch";
export const SORT_BY = "sortBy";
export const SORTING_DIRECTION = "sortingDirection";
export const PROJECT_ID = "projectId";
export const USER_ID = "userId";
export const ENTITY_TYPE = "entityType";
export const AUDIT_TYPE = "type";
export const DASHBOARD_ID = "dashboardId";
export const TENANT_ID = "tenantId";
export const DEVICE_SPECIFICATION_ID = "deviceSpecificationId";

// Response
export const RESPONSE_FORBIDDEN_STATUS = 403;

// Grid layout constants
export const GRID_LAYOUT_ROW_HEIGHT = 100;
export const GRID_COLUMNS = 24;
export const GRID_MARGIN_X = 0;
export const GRID_MARGIN_Y = 0;
export const GRID_PADDING_X = 0;
export const GRID_PADDING_Y = 0;

// Storage constants
export const SESSION_STORAGE_ACCOUNT = "user_account_session";
export const SESSION_STORAGE_PROJECT_ID = "projectId";
export const SESSION_STORAGE_USER_ID = "userId";

// Dashboard state
export const DASHBOARD_VIEW_STATE_PARAM_KEY = "state";
export const DASHBOARD_VIEW_ID = "viewId";
export const ENTITY_ID = "entityId";

// Widget configuration
export const ACTION_TYPE_OPEN_DASHBOARD = "openDashboard";
export const ACTION_SOURCE_TABLE_ROW = "tableRow";
export const DATETIME_VALUE_TYPE = "DATETIME";
export const BOOLEAN_VALUE_TYPE = "boolean";
export const DATASOURCE_CURRENT_DATE = "TODAY";
export const MISSING_ENTITY_ID = "MISSING ENTITY ID";

// Device specification
export const INPUT_TEXT_TYPE = "text";
export const INPUT_NUMBER_TYPE = "number";
export const INPUT_SELECT_TYPE = "select";

export const BOOLEAN_TRUE_OPTION = { label: "True", value: "true" };
export const BOOLEAN_FALSE_OPTION = { label: "False", value: "false" };

export const DISPATCH_ADD_TYPE = "added";
export const DISPATCH_CHANGE_TYPE = "changed";
export const DISPATCH_DELETE_TYPE = "deleted";

export const SNACKBAR_HIDEOUT_DURATION = 3000;
export const SNACKBAR_MAXIMUM_STACK = 5;
export const SNACKBAR_POSITION = { vertical: "bottom", horizontal: "left" };

// Device command log
export const CANCELABLE_COMMAND_LOG_STATUSES = [
  CommandLogResultStatus.QUEUED,
  CommandLogResultStatus.SCHEDULED,
];

// Dashboard view
export const DEFAULT_TENANT_DASHBOARD_NAME = "Tenant Overview";
export const DEFAULT_PROJECT_DASHBOARD_NAME = "Project Overview";
export const DEFAULT_DASHBOARD_VIEW_KEY = "DEFAULT";
export const DEFAULT_DASHBOARD_VIEW_NAME = "Default";
export const DEFAULT_DASHBOARD_VIEW_COLUMNS = 24;
export const DEFAULT_DASHBOARD_VIEW_PADDING = 0;

export const BREADCRUMB_MAXIMUM_VISIBLE = 3;
export const BREADCRUMB_FONT_SIZE = "16px";

export const DRAWER_WIDTH = 258;

export const RECENTS_MAXIMUM_VISIBLE_XL = 8;
export const RECENTS_MAXIMUM_VISIBLE_LG = 6;
export const RECENTS_MAXIMUM_VISIBLE_SM = 4;
export const RECENTS_MAXIMUM_VISIBLE_XS = 2;

//Query builder
export const QUERY_BUILDER_COMBINATOR_AND = "and";
export const QUERY_BUILDER_COMBINATOR_OR = "or";

// Device import
export const FILE_UPLOAD_CSV_TYPE = "text/csv";
export const FILE_UPLOAD_CSV_EXTENSION = ".csv";
export const PROJECT_ID_PARAM = "projectId";
export const DEVICE_SPECIFICATION_ID_PARAM = "deviceSpecificationId";
export const TEMPLATE_ID_PARAM = "templateId";
export const FILE_PARAM = "file";
export const ALERT_SUCCESS_TYPE = "success";
export const ALERT_WARNING_TYPE = "warning";
export const ALERT_ERROR_TYPE = "error";
export const DESKTOP_FONT_SIZE_CHART_BREAKPOINT = 1500;
export const DESKTOP_FONT_SIZE_CHART = 12;
export const DESKTOP_PADDING_SIZE_CHART = 10;
export const TABLET_FONT_SIZE_CHART_BREAKPOINT = 800;
export const TABLET_FONT_SIZE_CHART = 8;
export const TABLET_PADDING_SIZE_CHART = 8;
export const MOBILE_FONT_SIZE_CHART = 6;
export const MOBILE_PADDING_SIZE_CHART = 6;
export const DELIMITER_TYPE_MAP = new Map([
  ["COMMA", ","],
  ["SEMICOLON", ";"],
]);

// Map widget
export const MAP_DEVICES_QUERY_STALE_TIME = 1000 * 60 * 5;
export const MAP_ATTRIBUTES_QUERY_STALE_TIME = 1000 * 60 * 5;

// Image upload
export const IMAGE_UPLOAD_TYPES = [
  "image/png",
  "image/jpg",
  "image/jpeg",
  "image/gif",
].join(",");

export const IMAGE_UPLOAD_MAX_SIZE_IN_MB = 0.74;
export const IMAGE_MAP_UPLOAD_MAX_SIZE_IN_MB = 13.5;
export const BYTES_IN_MEGABYTE = 1024 * 1024;

// JSON Viewer themes
export const lightMui: NamedColorspace = {
  scheme: "Light MUI",
  author: "LEAPWISE",
  base00: "#f7f9fc", //background
  base01: "#0b8658",
  base02: "#d3d3d3", //indent lines
  base03: "#0b8658",
  base04: "#a7adba", //description text
  base05: "#0b8658",
  base06: "#0b8658",
  base07: "#0451a5", //key
  base08: "#0b8658",
  base09: "#a31415", //value
  base0A: "#0b8658",
  base0B: "#0b8658",
  base0C: "#0b8658", //numbers
  base0D: "#0b8658",
  base0E: "#0b8658",
  base0F: "#0b8658",
};

export const darkMui: NamedColorspace = {
  scheme: "Dark MUI",
  author: "LEAPWISE",
  base00: "#1b2635", //background
  base01: "#fff",
  base02: "#595959", //indent lines
  base03: "#fff",
  base04: "#878d97", //description text
  base05: "#fff",
  base06: "#fff",
  base07: "#9cdcfe", //key
  base08: "#fff",
  base09: "#ce9178", //value
  base0A: "#fff",
  base0B: "#fff",
  base0C: "#b5cea8", //numbers
  base0D: "#fff",
  base0E: "#fff",
  base0F: "#fff",
};

export const HEX_TRANSPARANCY_CODE_20 = "33";

// Integer Number input field
export const INTEGER_MIN_VALUE = -2147483648;
export const INTEGER_MAX_VALUE = 2147483647;

// File Mime Type
export const FileMimeType: Record<string, string> = {
  "audio/x-mpeg": "mpega",
  "application/postscript": "ps",
  "audio/x-aiff": "aiff",
  "application/x-aim": "aim",
  "image/x-jg": "art",
  "video/x-ms-asf": "asx",
  "audio/basic": "ulw",
  "video/x-msvideo": "avi",
  "video/x-rad-screenplay": "avx",
  "application/x-bcpio": "bcpio",
  "application/octet-stream": "exe",
  "image/bmp": "dib",
  "text/html": "html",
  "application/x-cdf": "cdf",
  "application/pkix-cert": "cer",
  "application/java": "class",
  "application/x-cpio": "cpio",
  "application/x-csh": "csh",
  "text/css": "css",
  "application/msword": "doc",
  "application/xml-dtd": "dtd",
  "video/x-dv": "dv",
  "application/x-dvi": "dvi",
  "application/vnd.ms-fontobject": "eot",
  "text/x-setext": "etx",
  "image/gif": "gif",
  "application/x-gtar": "gtar",
  "application/x-gzip": "gz",
  "application/x-hdf": "hdf",
  "application/mac-binhex40": "hqx",
  "text/x-component": "htc",
  "image/ief": "ief",
  "text/vnd.sun.j2me.app-descriptor": "jad",
  "application/java-archive": "jar",
  "text/x-java-source": "java",
  "application/x-java-jnlp-file": "jnlp",
  "image/jpeg": "jpg",
  "application/javascript": "js",
  "text/plain": "txt",
  "application/json": "json",
  "audio/midi": "midi",
  "application/x-latex": "latex",
  "audio/x-mpegurl": "m3u",
  "image/x-macpaint": "pnt",
  "text/troff": "tr",
  "application/mathml+xml": "mathml",
  "application/x-mif": "mif",
  "video/quicktime": "qt",
  "video/x-sgi-movie": "movie",
  "audio/mpeg": "mpa",
  "video/mp4": "mp4",
  "video/mpeg": "mpg",
  "video/mpeg2": "mpv2",
  "application/x-wais-source": "src",
  "application/x-netcdf": "nc",
  "application/oda": "oda",
  "application/vnd.oasis.opendocument.database": "odb",
  "application/vnd.oasis.opendocument.chart": "odc",
  "application/vnd.oasis.opendocument.formula": "odf",
  "application/vnd.oasis.opendocument.graphics": "odg",
  "application/vnd.oasis.opendocument.image": "odi",
  "application/vnd.oasis.opendocument.text-master": "odm",
  "application/vnd.oasis.opendocument.presentation": "odp",
  "application/vnd.oasis.opendocument.spreadsheet": "ods",
  "application/vnd.oasis.opendocument.text": "odt",
  "application/vnd.oasis.opendocument.graphics-template": "otg",
  "application/vnd.oasis.opendocument.text-web": "oth",
  "application/vnd.oasis.opendocument.presentation-template": "otp",
  "application/vnd.oasis.opendocument.spreadsheet-template": "ots",
  "application/vnd.oasis.opendocument.text-template": "ott",
  "application/ogg": "ogx",
  "video/ogg": "ogv",
  "audio/ogg": "spx",
  "application/x-font-opentype": "otf",
  "audio/flac": "flac",
  "application/annodex": "anx",
  "audio/annodex": "axa",
  "video/annodex": "axv",
  "application/xspf+xml": "xspf",
  "image/x-portable-bitmap": "pbm",
  "image/pict": "pict",
  "application/pdf": "pdf",
  "image/x-portable-graymap": "pgm",
  "audio/x-scpls": "pls",
  "image/png": "png",
  "image/x-portable-anymap": "pnm",
  "image/x-portable-pixmap": "ppm",
  "application/vnd.ms-powerpoint": "pps",
  "image/vnd.adobe.photoshop": "psd",
  "image/x-quicktime": "qtif",
  "image/x-cmu-raster": "ras",
  "application/rdf+xml": "rdf",
  "image/x-rgb": "rgb",
  "application/vnd.rn-realmedia": "rm",
  "application/rtf": "rtf",
  "text/richtext": "rtx",
  "application/font-sfnt": "sfnt",
  "application/x-sh": "sh",
  "application/x-shar": "shar",
  "application/x-stuffit": "sit",
  "application/x-sv4cpio": "sv4cpio",
  "application/x-sv4crc": "sv4crc",
  "image/svg+xml": "svgz",
  "application/x-shockwave-flash": "swf",
  "application/x-tar": "tar",
  "application/x-tcl": "tcl",
  "application/x-tex": "tex",
  "application/x-texinfo": "texinfo",
  "image/tiff": "tiff",
  "text/tab-separated-values": "tsv",
  "application/x-font-ttf": "ttf",
  "application/x-ustar": "ustar",
  "application/voicexml+xml": "vxml",
  "image/x-xbitmap": "xbm",
  "application/xhtml+xml": "xhtml",
  "application/vnd.ms-excel": "xls",
  "application/xml": "xsl",
  "image/x-xpixmap": "xpm",
  "application/xslt+xml": "xslt",
  "application/vnd.mozilla.xul+xml": "xul",
  "image/x-xwindowdump": "xwd",
  "application/vnd.visio": "vsd",
  "audio/x-wav": "wav",
  "image/vnd.wap.wbmp": "wbmp",
  "text/vnd.wap.wml": "wml",
  "application/vnd.wap.wmlc": "wmlc",
  "text/vnd.wap.wmlsc": "wmls",
  "application/vnd.wap.wmlscriptc": "wmlscriptc",
  "video/x-ms-wmv": "wmv",
  "application/font-woff": "woff",
  "application/font-woff2": "woff2",
  "model/vrml": "wrl",
  "application/wspolicy+xml": "wspolicy",
  "application/x-compress": "z",
  "application/zip": "zip",
};

export const booleanOptions: Array<Option> = [
  BOOLEAN_TRUE_OPTION,
  BOOLEAN_FALSE_OPTION,
];
