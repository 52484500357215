import {
  Bell,
  Book,
  Cloud,
  Codesandbox,
  Database,
  DownloadCloud,
  Home,
  Layout,
  Settings,
  Sliders,
  Tablet,
  Users,
  FileText,
} from "react-feather";
import { useTranslation } from "react-i18next";

import { PermIdentity, Schema } from "@mui/icons-material";

import { TFunction } from "i18next";

import { ProjectPayloadGet } from "domain/entities/project";
import { TenantPayloadGet } from "domain/entities/tenant";

import { EntityType } from "globalTypes";
import { SidebarItemsType } from "types/sidebar";

type TenantSectionProps = {
  t: TFunction;
  tenant?: TenantPayloadGet;
};

type ProjectSectionProps = {
  t: TFunction;
  project?: ProjectPayloadGet;
};

type NavItemsProps = {
  tenant?: TenantPayloadGet;
  project?: ProjectPayloadGet;
  t: TFunction;
};

export type NavItem = {
  title: string;
  pages: SidebarItemsType[];
  type?: string;
};

const SystemSection = () => {
  const { t } = useTranslation();
  return [
    {
      href: `/system/settings`,
      icon: Settings,
      title: t("sidebar.settings"),
      requiredPermissions: {
        [EntityType.SYSTEM]: ["UPDATE"],
      },
    },
    {
      href: `/system/tenants`,
      icon: Users,
      title: t("sidebar.tenants"),
      requiredPermissions: {
        [EntityType.TENANT]: ["READ"],
      },
    },
  ] as SidebarItemsType[];
};

const TenantSection = ({ tenant, t }: TenantSectionProps) => {
  return [
    {
      href: `/tenant/${tenant?.identifier.id}/overview`,
      icon: Home,
      title: t("sidebar.overview"),
      requiredPermissions: {
        [EntityType.TENANT]: ["READ"],
      },
    },
    {
      href: `/tenant/${tenant?.identifier.id}/project`,
      icon: Book,
      title: t("sidebar.projects"),
      requiredPermissions: {
        [EntityType.PROJECT]: ["READ"],
      },
    },
    {
      href: `/tenant/${tenant?.identifier.id}/authorities`,
      icon: PermIdentity,
      title: t("sidebar.authorities"),
      requiredPermissions: {
        [EntityType.AUTHORITY]: ["READ"],
      },
    },
    {
      href: `/tenant/${tenant?.identifier.id}/users`,
      icon: Users,
      title: t("sidebar.users"),
      requiredPermissions: {
        [EntityType.USER]: ["READ"],
      },
    },
    {
      href: `/tenant/${tenant?.identifier.id}/audit`,
      icon: Database,
      title: t("sidebar.audit"),
      requiredPermissions: {
        [EntityType.AUDIT]: ["READ"],
      },
    },
  ] as SidebarItemsType[];
};

const ProjectSection = ({ project, t }: ProjectSectionProps) => {
  return [
    {
      href: `/project/${project?.identifier.id}/overview`,
      icon: Home,
      title: t("sidebar.overview"),
      requiredPermissions: {
        [EntityType.PROJECT]: ["READ"],
      },
    },
    {
      href: `/project/${project?.identifier.id}/dashboard`,
      icon: Layout,
      title: t("sidebar.dashboard"),
      requiredPermissions: {
        [EntityType.DASHBOARD]: ["READ"],
      },
    },
    {
      href: `/project/${project?.identifier.id}/device`,
      icon: Tablet,
      title: t("sidebar.devices"),
      requiredPermissions: {
        [EntityType.DEVICE]: ["READ"],
      },
    },
    {
      href: `/project/${project?.identifier.id}/assets`,
      icon: Codesandbox,
      title: t("sidebar.assets"),
      requiredPermissions: {
        [EntityType.ASSET]: ["READ"],
      },
    },
    {
      href: `/project/${project?.identifier.id}/specifications`,
      icon: Sliders,
      title: t("sidebar.specifications"),
      requiredPermissions: {
        [EntityType.DEVICE_SPECIFICATION]: ["READ"],
      },
    },
    {
      href: `/project/${project?.identifier.id}/alarms`,
      icon: Bell,
      title: t("sidebar.alarms"),
      requiredPermissions: {
        [EntityType.ALARM]: ["READ"],
      },
    },
    {
      href: `/project/${project?.identifier.id}/integrations`,
      icon: Cloud,
      title: t("sidebar.integrations"),
      requiredPermissions: {
        [EntityType.INTEGRATION]: ["READ"],
      },
    },
    {
      href: `/project/${project?.identifier.id}/workflows`,
      icon: Schema,
      title: t("sidebar.workflows"),
      requiredPermissions: {
        [EntityType.WORKFLOW]: ["READ"],
      },
    },
    {
      href: `/project/${project?.identifier.id}/ota`,
      icon: DownloadCloud,
      title: t("sidebar.ota"),
      requiredPermissions: {
        [EntityType.OTA_PACKAGE]: ["READ"],
      },
    },
    {
      href: `/project/${project?.identifier.id}/audit`,
      icon: Database,
      title: t("sidebar.audit"),
      requiredPermissions: {
        [EntityType.AUDIT]: ["READ"],
      },
    },
    {
      href: `/project/${project?.identifier.id}/templates`,
      icon: FileText,
      title: t("sidebar.templates"),
      requiredPermissions: {
        [EntityType.DEVICE_IMPORT_TEMPLATE]: ["READ"],
      },
      children: [
        {
          href: `/project/${project?.identifier.id}/templates/deviceImport`,
          title: t("sidebar.deviceImport"),
        },
      ],
    },
  ] as SidebarItemsType[];
};

const navItems = ({ tenant, project, t }: NavItemsProps) => {
  const items: NavItem[] = [
    {
      title: t("sidebar.settings"),
      pages: SystemSection(),
    },
  ];

  if (tenant) {
    items.push({
      title: tenant.name,
      pages: TenantSection({ t, tenant }),
      type: "tenant",
    });
  }

  if (project) {
    items.push({
      title: t("sidebar.projects"),
      pages: ProjectSection({ t, project }),
      type: "project",
    });
  }
  return items;
};

export default navItems;
