import { TelemetryEntityType } from "fetch/telemetry";

import { TelemetryLatestPayload } from "domain/entities/telemetry";

import {
  AggregationFunction,
  Alarm,
  AlarmSeverity,
  AlarmStatusType,
  TelemetryGroupBy,
} from "globalTypes";

export enum WSRequestType {
  TELEMETRY = "SUBSCRIBE_TELEMETRY",
  VIDEO = "SUBSCRIBE_VIDEO",
  ALARM = "SUBSCRIBE_ALARM",
  UNSUBSCRIBE = "UNSUBSCRIBE",
}

export enum WSHealthcheckType {
  PING = "ping",
  PONG = "pong",
}

export enum WSCommandScope {
  LATEST_TELEMETRY = "LATEST_TELEMETRY",
  VIDEO = "VIDEO",
  UNSUBSCRIBE = "UNSUBSCRIBE",
}

export interface BaseWSSubscriptionRequest {
  tenantId: string;
  user: string;
  type: WSRequestType;
  widgetId: string;
  scope: WSCommandScope;
}

export interface TelemetryWSSubscriptionRequest
  extends BaseWSSubscriptionRequest {
  entities: string[];
  fields?: string[];
  timeframe: number;
  interval: number;
  limit: number;
  entityType?: TelemetryEntityType;
  aggregation: AggregationFunction;
  groupBy: TelemetryGroupBy;
}

export interface VideoWSSubscriptionRequest extends BaseWSSubscriptionRequest {
  deviceId: string;
  scope: WSCommandScope.VIDEO;
}

interface TimeFilter {
  from: string;
  to: string;
}

export interface AlarmWSSubscriptionRequest extends BaseWSSubscriptionRequest {
  entityId: string;
  alarmType: string;
  severity: AlarmSeverity;
  status: AlarmStatusType;
  startTimeFilter: TimeFilter;
  endTimeFilter: TimeFilter;
  acknowledgedTimeFilter: TimeFilter;
  clearedTimeFilter: TimeFilter;
}

export interface UnsubscribeWSRequest extends BaseWSSubscriptionRequest {}

interface WSError {
  code: number;
  message: string;
}

interface WSCode {
  name: string;
  code: number;
  category: string;
  description: string;
}

interface BaseWSResponse {
  widgetId: string;
  sessionId: string;
  error: WSError;
  code: WSCode;
}

export interface TelemetryWSResponsePayload extends BaseWSResponse {
  telemetries: TelemetryLatestPayload[];
}

export interface VideoWSResponsePayload extends BaseWSResponse {
  data: ArrayBuffer;
}

export interface AlarmWSResponsePayload extends BaseWSResponse {
  alarms: Alarm[];
}
