import { useEffect } from "react";

import { EntityId } from "globalTypes";

type EntityPayload = { identifier: EntityId };

function validateSingleEntity<T extends EntityPayload>(data: T): boolean {
  return Boolean(data.identifier);
}

function validateMultipleEntities<T extends EntityPayload>(data: T[]): boolean {
  return data.every((item) => validateSingleEntity(item));
}

function persistArrayEntity<T extends EntityPayload>(
  data: T[],
  persistFunction: (data: T) => void,
  persistedEntityId: string | undefined,
  persistFirstEntity?: boolean,
) {
  const storedEntity = data.find(
    (item) => item.identifier.id === persistedEntityId,
  );

  if (storedEntity) persistFunction(storedEntity);
  else if (
    persistFirstEntity &&
    (!persistedEntityId ||
      !data.some((entity) => entity.identifier.id === persistedEntityId))
  ) {
    persistFunction(data[0]);
  }
}

function persistSingleEntity<T extends EntityPayload>(
  data: T,
  persistFunction: (data: T) => void,
  persistedEntityId: string | undefined,
  checkIfActiveEntity?: boolean,
  alwaysReplaceEntity?: boolean,
) {
  if (
    (checkIfActiveEntity && data.identifier.id === persistedEntityId) ||
    !persistedEntityId ||
    alwaysReplaceEntity
  ) {
    persistFunction(data);
  }
}

export function usePersistEntity<T extends EntityPayload>(
  data: T | T[] | undefined,
  persistFunction: (data: T) => void,
  persistedEntityId: string | undefined,
  checkIfActiveEntity?: boolean,
  persistFirstEntity?: boolean,
  alwaysReplaceEntity?: boolean,
) {
  useEffect(() => {
    if (!data || (Array.isArray(data) && !data.length)) return;
    if (Array.isArray(data) && validateMultipleEntities(data)) {
      persistArrayEntity(
        data,
        persistFunction,
        persistedEntityId,
        persistFirstEntity,
      );
    } else if (!Array.isArray(data) && validateSingleEntity(data)) {
      persistSingleEntity(
        data,
        persistFunction,
        persistedEntityId,
        checkIfActiveEntity,
        alwaysReplaceEntity,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
}
