import { QueryClient } from "@tanstack/react-query";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SnackbarProviderProps } from "notistack";

import { timeframeDefaultValues } from "components/timeframe/form/constants";
import {
  TimeframeHistorical,
  TimeframeRealtime,
} from "components/timeframe/types";

import { DashboardPayloadGet } from "domain/entities/dashboard";
import { Grid, DashboardLayout } from "domain/entities/dashboardView";
import { WidgetPayloadGet } from "domain/entities/widget";

import { EntityId, Identifier } from "globalTypes";

import type { RootState } from "redux/store";

interface LayoutWidget {
  i: string;
  w: number;
  h: number;
  x: number;
  y: number;
}

export interface ViewLayout {
  viewId: string;
  layout: LayoutWidget[];
}

export interface ViewWidgetId {
  viewId: string;
  widgetId: string;
}

export interface ViewWidgetInstance {
  viewId: string;
  widget: WidgetPayloadGet;
}

export interface DashboardViewState {
  identifier: Identifier;
  dashboardId?: EntityId;
  layout?: DashboardLayout;
  widgets: Array<WidgetPayloadGet>;
  grid?: Grid;
  created?: string;
  description?: string;
  initial: boolean;
  key: string;
  name?: string;
}

export interface DashboardState {
  dashboard?: DashboardPayloadGet;
  views: DashboardViewState[];
  dashboardModified: boolean;
  viewsAdded: DashboardViewState[];
  viewsModified: string[];
  viewsDeleted: string[];
  viewsLayout: ViewLayout[];
  widgetsAdded: ViewWidgetInstance[];
  widgetsModified: ViewWidgetId[];
  widgetsDeleted: ViewWidgetId[];
  timeframe: Partial<TimeframeHistorical | TimeframeRealtime>;
}

export interface DashboardExportJson {
  dashboardId: string;
  queryClient: QueryClient;
  enqueueSnackbar: (
    message:
      | string
      | React.ReactElement<any, string | React.JSXElementConstructor<any>>
      | null,
    options?: SnackbarProviderProps,
  ) => void;
}

const initialState: DashboardState = {
  dashboard: undefined,
  views: [],
  dashboardModified: false,
  viewsAdded: [],
  viewsModified: [],
  viewsDeleted: [],
  viewsLayout: [],
  widgetsAdded: [],
  widgetsModified: [],
  widgetsDeleted: [],
  timeframe: timeframeDefaultValues,
};

function addIfNotExist<T>(arr: T[], value: T) {
  if (!arr.includes(value)) {
    arr.push(value);
  }
}

export const selectWidgetById = (
  state: RootState,
  widgetId: string | undefined,
) => {
  const views = state.dashboard.views;
  const widget = views
    .map((view) => view.widgets)
    .flat()
    .find((widget) => widget?.identifier?.id === widgetId);
  return widget || null;
};

export const selectViewById = (state: RootState, viewId: string) => {
  const views = state.dashboard.views;
  const view = views.find((view) => view.identifier.id === viewId);
  return view || null;
};

export const selectWidgetsByViewId = (state: RootState, viewId: string) => {
  const view = state.dashboard.views.find(
    (view) => view.identifier.id === viewId,
  );
  if (!view) {
    return [];
  }
  return view.widgets;
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    resetDashboardState(state) {
      return initialState;
    },
    resetEditState(state) {
      state.dashboardModified = false;
      state.viewsAdded = [];
      state.viewsModified = [];
      state.viewsDeleted = [];
      state.viewsLayout = [];
      state.widgetsAdded = [];
      state.widgetsModified = [];
      state.widgetsDeleted = [];
      state.timeframe = timeframeDefaultValues;
    },
    initialize(
      state,
      action: PayloadAction<Pick<DashboardState, "dashboard" | "views">>,
    ) {
      state.dashboard = action.payload.dashboard;
      state.views = action.payload.views;
    },
    initializeViews(state, action: PayloadAction<DashboardViewState[]>) {
      state.views = structuredClone(action.payload);
    },
    initializeWidgets(
      state,
      action: PayloadAction<{ viewId: string; widgets: WidgetPayloadGet[] }>,
    ) {
      const viewIndex = state.views.findIndex(
        (view) => view.identifier.id === action.payload.viewId,
      );
      if (viewIndex !== -1) {
        state.views[viewIndex].widgets = action.payload.widgets;
      }
    },
    addView(state, action: PayloadAction<DashboardViewState>) {
      state.views.push(action.payload);
      addIfNotExist(state.viewsAdded, action.payload);
    },
    editView(state, action: PayloadAction<DashboardViewState>) {
      const viewIndex = state.views.findIndex(
        (view) => view.identifier.id === action.payload.identifier.id,
      );
      if (viewIndex !== -1) {
        state.views[viewIndex] = action.payload;

        const addedViewIndex = state.viewsAdded.findIndex(
          (view) => view.identifier.id === action.payload.identifier.id,
        );

        if (addedViewIndex !== -1) {
          state.viewsAdded[addedViewIndex] = action.payload;
        } else {
          addIfNotExist(state.viewsModified, action.payload.identifier.id);
        }
      }
    },
    removeView(state, action: PayloadAction<string>) {
      if (state.dashboard) {
        state.dashboard.views = state.dashboard.views.filter(
          (view) => view.id !== action.payload,
        );
      }
      state.views = state.views.filter(
        (view) => view.identifier.id !== action.payload,
      );

      const addedViewIndex = state.viewsAdded.findIndex(
        (view) => view.identifier.id === action.payload,
      );

      if (addedViewIndex !== -1) {
        state.viewsAdded.splice(addedViewIndex, 1);
      } else {
        addIfNotExist(state.viewsDeleted, action.payload);
      }
    },
    modifyViewlayout(state, action: PayloadAction<ViewLayout>) {
      if (state.viewsLayout.length) {
        const viewLayoutIndex = state.viewsLayout.findIndex(
          (view) => view.viewId === action.payload.viewId,
        );
        if (viewLayoutIndex > -1)
          state.viewsLayout[viewLayoutIndex].layout = structuredClone(
            action.payload.layout,
          );
      } else state.viewsLayout.push(structuredClone(action.payload));
    },
    setDashboardConfiguration(
      state,
      action: PayloadAction<DashboardPayloadGet["configuration"]>,
    ) {
      if (state.dashboard) {
        state.dashboard.configuration = action.payload;
        state.dashboardModified = true;
      }
    },
    addWidget(state, action: PayloadAction<ViewWidgetInstance>) {
      const viewIndex = state.views.findIndex(
        (view) => view.identifier.id === action.payload.viewId,
      );
      if (viewIndex !== -1) {
        state.views[viewIndex].widgets.push(action.payload.widget);
        state.widgetsAdded.push(action.payload);
      }
    },
    updateWidget(state, action: PayloadAction<ViewWidgetInstance>) {
      const viewIndex = state.views.findIndex(
        (view) => view.identifier.id === action.payload.viewId,
      );
      if (viewIndex !== -1) {
        const widgetIndex = state.views[viewIndex].widgets.findIndex(
          (widget) =>
            widget.identifier.id === action.payload.widget.identifier.id,
        );
        if (widgetIndex !== -1) {
          state.views[viewIndex].widgets[widgetIndex] = action.payload.widget;

          const addedWidgetIndex = state.widgetsAdded.findIndex(
            (it) =>
              it.widget.identifier.id === action.payload.widget.identifier.id,
          );

          if (addedWidgetIndex !== -1) {
            state.widgetsAdded[addedWidgetIndex].widget = action.payload.widget;
          } else if (
            !state.widgetsModified.some(
              (w) => w.widgetId === action.payload.widget.identifier.id,
            )
          ) {
            state.widgetsModified.push({
              viewId: action.payload.viewId,
              widgetId: action.payload.widget.identifier.id,
            });
          }
        }
      }
    },
    removeWidget(state, action: PayloadAction<ViewWidgetId>) {
      const viewIndex = state.views.findIndex(
        (view) => view.identifier.id === action.payload.viewId,
      );
      if (viewIndex !== -1) {
        state.views[viewIndex].widgets = state.views[viewIndex].widgets.filter(
          (widget) => widget.identifier.id !== action.payload.widgetId,
        );
        const addedWidgetIndex = state.widgetsAdded.findIndex(
          (it) => it.widget.identifier.id === action.payload.widgetId,
        );
        if (addedWidgetIndex !== -1) {
          state.widgetsAdded.splice(addedWidgetIndex, 1);
        } else {
          state.widgetsDeleted.push(action.payload);
        }
      }
    },
    updateTimeframe(
      state,
      action: PayloadAction<Partial<TimeframeHistorical | TimeframeRealtime>>,
    ) {
      state.timeframe = action.payload;
    },
  },
});

export const {
  initialize,
  initializeViews,
  initializeWidgets,
  resetDashboardState,
  addView,
  removeView,
  modifyViewlayout,
  setDashboardConfiguration,
  addWidget,
  resetEditState,
  editView,
  updateWidget,
  removeWidget,
  updateTimeframe,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
