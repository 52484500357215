import { PropsWithChildren, useEffect } from "react";

import { useTranslation } from "react-i18next";

import {
  CssBaseline,
  Typography,
  Box,
  Divider,
  List,
  ListItem,
  Skeleton,
  Alert,
  Link,
} from "@mui/material";

import styled from "@emotion/styled";
import { darken } from "polished";

import GlobalStyle from "components/GlobalStyle";
import Loader from "components/Loader";

import { usePersistEntity } from "hooks/usePersistEntity";
import useUserPreferencesStore from "hooks/useUserPreferences";

import { useUserProfileById } from "fetch/userProfile";

import { TenantPayloadGet } from "domain/entities/tenant";

import { getDecodedToken } from "utils/jwt";

export const TenantSelectPage = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "component.tenantSelector",
  });
  const storage = useUserPreferencesStore();

  const {
    data: user,
    refetch: refetchUser,
    isError,
    isFetching,
    isLoading,
  } = useUserProfileById({
    variables: {
      pathParams: {
        userId: getDecodedToken()?.userId ?? "",
      },
    },
    enabled: !!getDecodedToken()?.userId,
  });

  usePersistEntity(user?.payload, storage.setUser, storage.user?.identifier.id);

  useEffect(() => {
    if (user?.payload.tenants && user?.payload.defaults.tenantId) {
      const tenant = user?.payload.tenants.find(
        (tenant) => tenant.identifier.id === user?.payload.defaults.tenantId,
      );
      if (tenant) {
        storage.setTenant(tenant);
        window.location.replace("/");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.payload.tenants, user?.payload.defaults.tenantId]);

  if (isFetching || user?.payload.defaults.tenantId)
    return (
      <Box
        style={{
          height: "100vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loader />
      </Box>
    );

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <TenantSelect>
        <List
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "1rem",
            flexDirection: "column",
          }}
        >
          {isFetching || isLoading ? (
            <TenantSkeleton>
              <Skeleton
                variant="circular"
                width={40}
                height={40}
                sx={{ borderRadius: "50%" }}
              />
              <Skeleton variant="text" width={200} height={40} />
            </TenantSkeleton>
          ) : (
            <Tenant tenants={user?.payload.tenants ?? []} />
          )}
        </List>
        {isError && !isLoading ? (
          <Alert severity="error">
            {t("error")}

            <Link component="button" onClick={() => refetchUser()}>
              {t("tryAgain")}
            </Link>
          </Alert>
        ) : null}
      </TenantSelect>
    </Root>
  );
};

const TenantSelect = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "component.tenantSelector",
  });

  return (
    <TenantSelectorContainer>
      <Divider />
      <Typography
        variant="h3"
        sx={{
          textAlign: "center",
          fontWeight: "bold",
          marginTop: "1rem",
        }}
      >
        {t("headerMsg")}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          textAlign: "center",
          marginBottom: "2rem",
        }}
      >
        {t("subHeaderMsg")}
      </Typography>
      {children}
    </TenantSelectorContainer>
  );
};

const Tenant = ({ tenants }: { tenants: TenantPayloadGet[] }) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "component.tenantSelector",
  });
  const setTenant = useUserPreferencesStore((state) => state.setTenant);
  const activeTenant = useUserPreferencesStore((state) => state.tenant);

  return (
    <>
      {tenants?.map((tenant, index) => {
        return (
          <TenantContainer key={tenant.identifier.id}>
            <TenantListItem
              onClick={() => {
                setTenant(tenant);
                window.location.replace("/");
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "row", gap: 5 }}>
                <img
                  alt="avatar"
                  height={40}
                  width={40}
                  src={tenant.image}
                  loading="lazy"
                  style={{ borderRadius: "10%" }}
                />
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography variant="h6">{tenant.name}</Typography>
                  <Typography variant="body1">{tenant.description}</Typography>
                </Box>
              </Box>
              {tenant.identifier.id === activeTenant?.identifier?.id ? (
                <Typography variant="body1" color="GrayText">
                  {t("active")}
                </Typography>
              ) : null}
            </TenantListItem>
            {index !== tenants.length - 1 ? (
              <Divider component="li" flexItem />
            ) : null}
          </TenantContainer>
        );
      })}
    </>
  );
};

const Root = styled(Box)`
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  background-color: ${(props) => props.theme.sidebar.header.background};
`;

const TenantContainer = styled.div`
  height: 100%;
  width: 100%;
`;

const TenantSelectorContainer = styled(Box)`
  border: 0.5px solid ${(props) => props.theme.palette.divider};
  min-height: auto;
  min-width: 30rem;
  border-radius: 4px;
  background-color: ${(props) => props.theme.palette.background.default};
`;

const TenantListItem = styled(ListItem)`
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  width: 100%;
  padding: 0.5rem 2.5rem 0.5rem 2.5rem;
  cursor: pointer;

  &:hover {
    background-color: ${(props) =>
      darken(0.03, props.theme.palette.background.default)};
  }
`;

const TenantSkeleton = styled(ListItem)`
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  justify-content: flex-start;
  width: 100%;
  padding: 0.5rem 3rem 0.5rem 3rem;
`;
