import axios, {
  AxiosError,
  AxiosHeaders,
  InternalAxiosRequestConfig,
} from "axios";

import useUserPreferencesStore from "hooks/useUserPreferences";

import { authenticationApi, logout } from "fetch/authentication";

import { getAccessToken, getRefreshToken, setJwtData } from "utils/jwt";

let isFetchingToken = false;
let refreshTokenPromiseInstance: Promise<string | null> | null = null;

export function objectToFormData(
  data: Record<string, string | Blob>,
): FormData {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    const item = data[key];
    if (item instanceof File) {
      formData.append(key, item, item.name);
    } else {
      formData.append(key, item);
    }
  });
  return formData;
}

const refreshTokenPromise = async (): Promise<string | null> => {
  try {
    const refreshToken = await refreshTokenFn();
    return refreshToken;
  } catch (error) {
    return null;
  }
};

const refreshTokenFn = async (): Promise<string> => {
  const refreshToken = getRefreshToken();

  return new Promise<string>((resolve, reject) => {
    if (refreshToken)
      authenticationApi
        .refreshToken(refreshToken)
        .then((response) => {
          setJwtData(response.data);
          resolve(response.data.access_token);
        })
        .catch((error) => reject(error));
    else reject(new Error("Network error, refresh token does not exist"));
  });
};

export const handleRequest = async (
  config: InternalAxiosRequestConfig,
): Promise<InternalAxiosRequestConfig> => {
  const accessToken = getAccessToken();

  if (!accessToken || isFetchingToken) {
    const controller = new AbortController();
    controller.abort();
    config.signal = controller.signal;
    return config;
  }

  if (config.headers) {
    const headers = new AxiosHeaders({
      tenantId:
        config.headers.get("tenantId") ??
        useUserPreferencesStore.getState().tenant?.identifier.id!,
      Authorization: `Bearer ${accessToken}`,
      Accept: "*/*",
    });

    config.headers = config.headers.concat(headers);
  }

  return config;
};

export const handleResponseError = async (error: AxiosError) => {
  const originalRequest = error.config as InternalAxiosRequestConfig<any>;

  if (error.response && error.response.status === 401) {
    try {
      if (!isFetchingToken) {
        isFetchingToken = true;
        refreshTokenPromiseInstance = refreshTokenPromise() as Promise<string>;
      }

      const refreshToken = await refreshTokenPromiseInstance;

      originalRequest.headers["Authorization"] = `Bearer ${refreshToken}`;

      return axios(originalRequest);
    } catch {
      logout();
    } finally {
      isFetchingToken = false;
    }
  }

  return Promise.reject(error);
};

export const handleAuthRequestError = async (error: AxiosError) => {
  if (error.response && [400, 401, 403].includes(error.response.status))
    logout();
};
