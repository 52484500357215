import React, { ReactNode } from "react";

import { matchPath } from "react-router-dom";

import { RequiredPermissionDefinition } from "utils/permissionGuard";

import { SidebarItemsType } from "types/sidebar";

import SidebarNavList from "./SidebarNavList";
import SidebarNavListItem from "./SidebarNavListItem";

type Arguments = {
  depth: number;
  page: SidebarItemsType;
  items: ReactNode[];
  currentRoute: string;
  permissionGuard: (permissions: RequiredPermissionDefinition) => boolean;
};

const reduceChildRoutes = ({
  items,
  page,
  depth,
  currentRoute,
  permissionGuard,
}: Arguments) => {
  if (page.requiredPermissions && !permissionGuard(page.requiredPermissions)) {
    return items;
  }

  if (page.children) {
    const open = page.href
      ? !!matchPath(
          {
            path: page.href,
            end: false,
          },
          currentRoute,
        )
      : false;

    items.push(
      <SidebarNavListItem
        depth={depth}
        icon={page.icon}
        key={page.title}
        badge={page.badge}
        open={open}
        title={page.title}
        href={page.href}
      >
        <SidebarNavList depth={depth + 1} pages={page.children} />
      </SidebarNavListItem>,
    );
  } else {
    items.push(
      <SidebarNavListItem
        depth={depth}
        href={page.href}
        icon={page.icon}
        key={page.title}
        badge={page.badge}
        title={page.title}
      />,
    );
  }

  return items;
};

export default reduceChildRoutes;
