import {
  AssetPayloadPost,
  AssetPayloadPut,
  AssetResponse,
  AssetResponseSearch,
  AssetReferenceResponseSearch,
} from "domain/entities/asset";

import { ReferenceQueryParams } from "globalTypes";

import axiosClient from "./axios";
import {
  createApiQuery,
  createApiQueryInfinite,
  createSearchApiQuery,
  SearchParams,
  useApiMutation,
} from "./queryUtils";

export const assetApi = {
  createAsset: (body: AssetPayloadPost) =>
    axiosClient.post<AssetResponse>("/hive/asset", body),
  updateAssetById: (assetId: string, body: AssetPayloadPut) =>
    axiosClient.put<AssetResponse>(`/hive/asset/${assetId}`, body),
  deleteAssetById: (assetId: string) =>
    axiosClient.delete<AssetResponse>(`/hive/asset/${assetId}`),
};

export interface AssetSearchQueryParams {
  projectId: string;
  name?: string;
  type?: string;
  label?: string;
}

export interface AssetReferenceQueryParams extends ReferenceQueryParams {}

export const useAssetSearch = createSearchApiQuery<
  AssetResponseSearch,
  undefined,
  AssetSearchQueryParams
>("/hive/asset");

export const useAssetSearchInfinite = createApiQueryInfinite<
  AssetResponseSearch,
  undefined,
  AssetSearchQueryParams & SearchParams,
  number
>("/hive/asset", {
  getNextPageParam: (lastPage) => {
    if (lastPage.page < lastPage.numberOfPages) {
      return lastPage.page + 1;
    }
  },
});

export const useAssetById = createApiQuery<AssetResponse, { assetId: string }>(
  "/hive/asset/{assetId}",
  60 * 1000,
);

export const useAssetReferenceSearch = createApiQuery<
  AssetReferenceResponseSearch,
  undefined,
  AssetReferenceQueryParams
>("/hive/asset/reference");

export const useAssetMutationUpdate = (id: string | undefined) => {
  return useApiMutation(
    (payload: AssetPayloadPut) => {
      return assetApi.updateAssetById(id ?? "", payload);
    },
    (response) => {
      return response.data?.responseDetail ?? "OK";
    },
    {
      meta: {
        dataKeys: [useAssetById.getPrimaryKey(), { pathParams: { otaId: id } }],
      },
    },
  );
};
