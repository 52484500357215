import React, { useCallback, useEffect } from "react";

import { useTranslation } from "react-i18next";

import {
  IconButton as MuiIconButton,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";

import styled from "@emotion/styled";

import useUserPreferencesStore from "hooks/useUserPreferences";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

const Flag = styled.img`
  border-radius: 50%;
  width: 22px;
  height: 22px;
`;

type LanguageProps = {
  icon: string;
  name: string;
};
type LanguageOptionsType = {
  [key: string]: LanguageProps;
};

export const languageOptions: LanguageOptionsType = {
  "en-GB": {
    icon: "/static/img/flags/gb.png",
    name: "English",
  },
  "hr-HR": {
    icon: "/static/img/flags/hr.png",
    name: "Hrvatski",
  },
};

function NavbarLanguagesDropdown() {
  const { i18n, t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = React.useState<LanguageProps>(
    languageOptions["en-GB"],
  );
  const [anchorMenu, setAnchorMenu] = React.useState<any>(null);
  const storage = useUserPreferencesStore();

  const handleLanguageChange = useCallback(
    (locale: string) => {
      i18n.changeLanguage(locale);
      setSelectedLanguage(languageOptions[locale]);
      closeMenu();
    },
    [i18n],
  );

  useEffect(() => {
    const lang = i18n.language ?? storage.language;

    if (languageOptions[lang]) setSelectedLanguage(languageOptions[lang]);
  }, [i18n, storage]);

  const toggleMenu = (event: React.SyntheticEvent) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  return (
    <React.Fragment>
      <Tooltip title={t("navbar.languages.tooltip")}>
        <IconButton
          aria-owns={anchorMenu ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="large"
        >
          <Flag src={selectedLanguage.icon} alt={selectedLanguage.name} />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        {Object.keys(languageOptions).map((language: string) => (
          <MenuItem
            key={language}
            onClick={() => handleLanguageChange(language)}
          >
            {languageOptions[language].name}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
}

export default NavbarLanguagesDropdown;
