import {
  UserProfilePayloadPut,
  UserProfileResponse,
} from "domain/entities/userProfile";

import axiosClient from "./axios";
import { createApiQuery } from "./queryUtils";

export const userProfileApi = {
  updateUserProfileById: (userId: string, data: UserProfilePayloadPut) =>
    axiosClient.put<UserProfileResponse>(`/hive/user/profile/${userId}`, data),
  resetUserProfilePassword: (userId: string) =>
    axiosClient.put<boolean>(`/hive/user/profile/${userId}/password/reset`),
};

export const useUserProfileById = createApiQuery<
  UserProfileResponse,
  { userId: string }
>("/hive/user/profile/{userId}");
